/**
 * url相关功能
 */

export default {
  /**
   * 获取url search参数
   * @param name 名称
   * @param default_value 默认值
   */
  getQuery(name:string, default_value?:any){
    var urlpara = location.search;
    var par:any = {};
    if (urlpara != "") {
      urlpara = urlpara.substring(1, urlpara.length);
      var para = urlpara.split("&");
      var parname;
      var parvalue;
      for (var i = 0; i < para.length; i++) {
        parname = para[i].substring(0, para[i].indexOf("=")).toLowerCase();
        parvalue = para[i].substring(para[i].indexOf("=") + 1, para[i].length);
        par[parname] = parvalue;
      }
    }
    if(typeof (par[name]) != "undefined"){
      return par[name];
    }
    else if(default_value != undefined){
      return default_value
    }
    else{
      return null;
    }
  },
  /**
   * 返回增加参数的url,如果已经有此参数，则替换
   * @param name 
   * @param value 
   * @returns 
   */
  addThisPageParam(name:string | Array<string>, value:string | Array<string>){
    var urlpara = location.search;
    var par:any = {};
    if (urlpara != "") {
      urlpara = urlpara.substring(1, urlpara.length);
      var para = urlpara.split("&");
      var parname;
      var parvalue;
      for (var i = 0; i < para.length; i++) {
        parname = para[i].substring(0, para[i].indexOf("="));
        parvalue = para[i].substring(para[i].indexOf("=") + 1, para[i].length);
        par[parname] = parvalue;
      }
    }

    if(name instanceof Array && value instanceof Array){
      name.forEach((v, index)=>{
        par[v] = encodeURIComponent(value[index]) 
      })
    }
    else{
      par[name as string] = encodeURIComponent(value as string)      
    }


    return '?' + Object.keys(par).map(v=>{
      return v + '=' + par[v]
    }).join('&')
  }
}