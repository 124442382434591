/**
 * 领涨概念
 */

import React,{useState, useEffect} from "react"
import './index.less'
import data, { conceptitem } from "./data";
import txt from "../../modules/txt";

/** 领涨概念 */
function TopConcept(){

  const [showdata, setShowData] = useState([] as Array<conceptitem>)

  useEffect(()=>{
    data().then(list=>{
      setShowData(list)
    })
  }, [])

  if(showdata.length == 0){
    return (
      <></>
    )
  }

  return (
    <>
      <div className="topconcept">
        <div className="topconcept_t">领涨概念</div>
        <div className="topconcept_list">
          <table className="topconcept_table">
            <thead>
              <tr>
                <th className="thname">名称</th>
                <th>涨跌幅</th>
                <th>领涨股</th>
              </tr>
            </thead>
            <tbody>
              {showdata.map((v,index)=>
                <tr key={v.name}>
                  <td><span className={`seq seq${index + 1}`}>{index + 1}</span><span><a href={`/web/s?keyword=${encodeURIComponent(v.name)}`} tracker-eventcode="dfcfwss.ssh.lzgn.nrdj">{v.name}</a></span></td>
                  <td><span dangerouslySetInnerHTML={{__html:txt.textNumColor2(v.zdf, v.zd, '%')}}></span></td>
                  <td><a href={`/web/s?keyword=${encodeURIComponent(v.stock_name)}`} tracker-eventcode="dfcfwss.ssh.lzgn.nrdj">{v.stock_name}</a></td>
                </tr>              
              )}

            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default TopConcept