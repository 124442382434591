/**
 * 搜索历史记录
 */

import cookie from "../cookie";


export default {
    _count: 10,             // 最大历史记录的个数
    _histotyLength: 20,     // 历史记录中超过的字数不记录（40表示最大20个汉字）
    _historyCookieName: "emshistory",    //  cookie名称

    // 获取历史记录
    getHistory: function(){
        // cookie.delCookie("serachhistory");  // 废弃这个cookie
        var arrstr = cookie.get(this._historyCookieName);
        if (arrstr) {
            return JSON.parse(arrstr);
        } else {
            return [];
        }
    },

    /**
     * 判断是否重复, 如果重复，则删除
     * 
     * @param {any} str : 关键字
     * @returns 
     */
    unHistory: function(str:string){
        var list = this.getHistory();
        for (var i = 0; i < list.length; i++) {
            if (str == list[i]) {
                list.splice(i, 1);
                i--;
            }
        }
        return list;
    },

    // 增加一条记录
    addHistory: function(str:string){
        var list = this.unHistory(str);
        
        if (str && this.txtLength(str) <= this._histotyLength) {
            if (list.length >= this._count) {
                list.pop();
            }
            list.unshift(str);
            cookie.set(this._historyCookieName, JSON.stringify(list), 90, "eastmoney.com");
        }
    },

    

    // 计算文字的长度，汉字算两个长度
    txtLength: function (txt:string) {
        var thislength = 0;
        for (var i = 0; i < txt.length; i++) {
            if (txt.charCodeAt(i) > 255) {
                thislength += 2;
            } else {
                thislength++;
            }
        }
        return thislength;
    }



}