/**
 * 今日热搜数据
 */

export interface hotsearchitem{
  KeyPhrase: string,
  HotKeywordStatus: string,
  Color: string,
  JumpAddress: string  
}

export default async function():Promise<Array<hotsearchitem>>{
  return $.ajax({
    url: '//searchadapter.eastmoney.com/api/hotkeyword/get?count=20&token=32A8A21716361A5A387B0D85259A0037&cb=?',
    type: 'GET',
    dataType: 'jsonp'
  }).then(back=>{
    return back.Data
  })
}