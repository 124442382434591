/**
 * 首页 - 欢迎页面
 */

import React,{useState, useEffect} from "react"
import "../../less/modules/base.less";
import HotSearch from "../hot_search";
import TopConcept from "../top_concept";
import recommend_data, {type_recommend_news} from "../../modules/data/recommend";
import './index.less'
import _ from "lodash";
import search_history from "../../modules/search_history";

function RecommendNews(){

  const [showdata, SetShowData] = useState([] as Array<type_recommend_news>)
  const [shownew, SetShowNew] = useState(false)
  let shownewcd:any

  const getData = async (type:'push' | 'unshift')=>{
    let back = await recommend_data.getWelcomeList()
    back = _.unionBy(back, v=>v.Id)
    if(back.length > 0){
      if(type == 'push'){
        SetShowData(_.unionBy(showdata.concat(back), v=>v.Id))
      }
      else if(type == 'unshift'){
        SetShowData(_.unionBy(back.concat(showdata), v=>v.Id))
      }
    }
  }

  const closetips = function(e:React.MouseEvent){
    e.preventDefault()
    SetShowNew(false)
    shownewcd = setTimeout(()=>{
      SetShowNew(true)
    }, 60 * 3 * 1000)    
  }

  const loadNew = async function(e:React.MouseEvent){
    $('html, body').animate({
      scrollTop: 0
    }, 200);  

    SetShowNew(false)

    shownewcd = setTimeout(()=>{
      SetShowNew(true)
    }, 60 * 3 * 1000)
    
    getData('unshift')
    e.preventDefault()
  }

  const loadMore = async function(){
    getData('push')
  }  

  useEffect(()=>{
    getData('push')
    shownewcd = setTimeout(()=>{
      SetShowNew(true)
    }, 60 * 3 * 1000)

    //新文章 固定
    let nllnew_m = $('#nllnew_m')

    let nllnew_m_offsettop = nllnew_m.offset()!.top
    let newtip_fix_fun = _.throttle(function() {
      let nllnew = $('.nllnew')      
      let windowscrolltop = $(window).scrollTop() as number
      if(windowscrolltop + 21 > nllnew_m_offsettop){
        nllnew.addClass('nllnew_close_fix')
      }
      else{
        nllnew.removeClass('nllnew_close_fix')
      }
      
    }, 50, {leading: false})
    $(window).on('scroll', newtip_fix_fun);


    return ()=>{
      try {
        $(window).off('scroll', newtip_fix_fun)
        clearTimeout(shownewcd)
      } catch (error) {
        
      }
    }
  }, [])



  useEffect(()=>{
    let newbottom = $('#newbottom')
    let loadmore_fun = _.debounce(function() {
      let newbuttom_offsettop = newbottom.offset()!.top
      let windowheight = $(window).height() as number
      let windowscrolltop = $(window).scrollTop() as number
      if(windowheight + windowscrolltop - 10 > newbuttom_offsettop){
        //loadMore()
        getData('push')
      }
      
    }, 300, {leading: false})
    $(window).on('scroll', loadmore_fun);

    return ()=>{
      $(window).off('scroll', loadmore_fun)
    }
  }, [showdata])



  return (
    <> 
      <div id="nllnew_m"></div>
      {shownew &&
        <>
          <div className="nllnew" onClick={loadNew} tracker-eventcode="dfcfwss.sssy.nkngxqd.nrdj">
            您有未读推荐文章，点击查看
            <a href="#" className="nllnew_close" onClick={(e)=>{closetips(e);e.stopPropagation()}}>×</a>
          </div>          
        </>
      }
      <div className="nlllist">
        {showdata.map(v=>
          <div className="nlllitem" key={v.Id}>
            {v.ImageUrl &&
              <div className="nll_img">
                <img src={v.ImageUrl} />
              </div>
            }
            <div className="nll_c">
              <div className="nll_t" tracker-eventcode="dfcfwss.sssy.nkngxqd.nrdj">
                <a href={v.Url} target="_blank">{v.Title}</a>
              </div>
              <div className="nll_tags">
                {v.LightTags == 'qrqmdefault' &&
                  <span className="nll_tag">推荐</span>
                }
              </div>
            </div>
            <div className="clearfix"></div>
          </div>      
        )}
      </div>
      <div id="newbottom"></div>
    </>

  )
}


function Welcome(){

  useEffect(()=>{
    if(window.suggest2017){
      var newsuggest = new window.suggest2017({ //新建实例
          newapi: true,
          inputid: 'searchSuggest', //参数部分
          width: 518,
          showstocklink: false,
          shownewtips: true,      // 显示搜索框后面的气泡
          newtipsoffset: {       // 气泡的设置
              top: 6,
              left: 0
          },
          hotStockLink: function (result:any) {
              return '/web/s?keyword=' + encodeURIComponent(result.Name);
          },
          onConfirmStock: function (msg:any) {
            search_history.addHistory(msg.stock.shortName)
              location.href = "/web/s?keyword=" + encodeURIComponent(msg.stock.shortName);
          },
          onSubmit: function (result:any) {
            if(result.key == ''){
              return false
            }
            search_history.addHistory(result.key)
            location.href = "/web/s?keyword=" + encodeURIComponent(result.key);
            return false;
          }
      })
    }

    //右侧fixed
    let welrfix_m = $('#welrfix_m')
    let welrfix = $('#welrfix')
    let newbuttom_offsettop = welrfix_m.offset()!.top
    let searchbox = $('#searchbox')
    let rightfix_fun = _.throttle(function() {
      let windowscrolltop = $(window).scrollTop() as number
      if(windowscrolltop + 50 > newbuttom_offsettop){
        welrfix.addClass('welrfix_f')
      }
      else{
        welrfix.removeClass('welrfix_f')
      }
      if(windowscrolltop > 240){
        searchbox.addClass('searchbox_fixed')
      }
      else{
        searchbox.removeClass('searchbox_fixed')
      }      
      
    }, 50, {leading: false})
    $(window).on('scroll', rightfix_fun);

    return ()=>{
      $(window).off('scroll', rightfix_fun)
    }
  }, [])

  return (
    <div className="weblcome">
      <div className="searchbox_c">
        <div className="searchbox" id="searchbox">
          <div className="logo">
            <img src="/content/img/logo.png" alt="东方财富网" />
          </div>
          <div className="searchform">
            <img src="/content/img/logo_s.png" alt="东方财富网" className="sf_logo" />
            <form action="" className="searchform_form">
              <input type="text" id="searchSuggest" className="minput" /><input type="submit" className="msubmit" value="搜索" />
            </form>
          </div>
        </div>  
      </div>
      <div className="container">
        <div className="newslist">
          <div className="nll">
            <div className="nllt">
              <span>您可能感兴趣的</span>
            </div>
            <RecommendNews></RecommendNews>
          </div>
          <div className="nlr">
            <div id="welrfix_m"></div>
            <div id="welrfix">
              <HotSearch></HotSearch>
              <TopConcept></TopConcept>            
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>    
    </div>

  )
}

export default Welcome