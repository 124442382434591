/**
 * 领涨概念数据
 */

export interface conceptitem{
  name: string,
  zdf: string,
  zd: number,
  stock_name: string,
  stock_market: number,
  stock_code:string  
}

export default async function():Promise<Array<conceptitem>>{
  return $.ajax({
    url: '//push2.eastmoney.com/api/qt/clist/get?pn=1&pz=10&po=1&np=1&ut=fa5fd1943c7b386f172d6893dbfba10b&fltt=2&invt=2&fid=f3&fs=m:90+t:3&fields=f1,f2,f3,f4,f14,f12,f13,f62,f128,f136,f1520e76d4e&cb=?',
    type: 'GET',
    dataType: 'jsonp'
  }).then(back=>{
    if(back.rc == 0 && back?.data?.diff){
      return back.data.diff.map((v:any)=>{
        return {
          name: v.f14,
          zdf: v.f3,
          zd: v.f4,
          stock_name: v.f128,
          stock_market: v.f141,
          stock_code: v.f140
        }
      })
    }
    return []
  })
}