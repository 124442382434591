/**
 * 通用功能
 */
import React from "react";
import ReactDom from "react-dom";
import url from "../modules/url";
import "../less/modules/common.less";

//如果不是首页，搜索字符串为空，则返回首页
const inputkeyword = $.trim(decodeURIComponent(url.getQuery('keyword')))
if(inputkeyword == '' && self.location.pathname != '/'){
  self.location.href = '/'
}

//老地址跳转新地址
let pathname = location.pathname.toLowerCase()
//话题
if(pathname == '/tiezi/s' && url.getQuery('swtype') == '4'){
  location.href = '/huati/s?keyword=' + encodeURIComponent(inputkeyword)
}
//找人
if(pathname == '/tiezi/s' && url.getQuery('swtype') == '3'){
  location.href = '/user/s?keyword=' + encodeURIComponent(inputkeyword)
}
//搜吧
if(pathname == '/tiezi/s' && url.getQuery('swtype') == '2'){
  location.href = '/bar/s?keyword=' + encodeURIComponent(inputkeyword)
}

/**
 * 返回顶部
 */
import BackTop from "../components/backtop";
var backtopdiv = document.createElement('div')
ReactDom.render(
  React.createElement(BackTop),
  backtopdiv
)
document.body.appendChild(backtopdiv)