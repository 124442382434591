/**
 * 操作字符串
 */

export default {
  /**
   * 字符串长度 一个汉字算2个
   * 
   * @param {any} txt 
   * @returns 
   */
  txtLength (txt:string) {
    var thislength = 0;
    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
    }
    return thislength;
  },
    /**
   * 字符串截取 (一个汉字算2个)
   * 
   * @param {string} txt 输入文本
   * @param {int} n 截取多少个字 一个汉字算2个
   * @param {boolean} needtip 是否需要全文提示
   * @param {string} postfix 自定义截断后缀，默认...
   * @returns 
   */
  txtLeft: function (txt:string, n:number, needtip:boolean = false, postfix:string = '...') {
    if( txt == null || txt == "" ){
      return "";
    }

    var thislength = 0;
    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
      
      if (thislength > n + postfix.length) {
        if(needtip){
          return '<span title="' + txt + '">' + txt.substring(0, i) + postfix + "</span>";
        }
        else{
          return txt.substring(0, i) + postfix;
        }
        break;
      }
    }
    return txt;
  },
  /**
   * 根据数字颜色化文字
   * @param text 文字
   * @param num 数字
   * @param hz 后缀
   */
  textNumColor: function(text:string, num:any, hz?:string){
    if (hz == undefined) {
      hz = ''
    }
    if (num == null || isNaN(num)) {
      return '<span class="quotenone">' + text + '</span>'
    }
    else{
      num = num / 1
    }

    if (num > 0) {
      return '<span class="quoteup">' + text + hz + '</span>'
    }
    else if (num < 0) {
      return '<span class="quotedown">' + text + hz + '</span>'
    } 
    else if (num == 0) {
      return '<span class="quotedraw">' + text + hz + '</span>'
    }
    else{
      return '<span class="quotenone">' + text + '</span>'
    }
  },
  textNumColor2: function(text:string, num:any, hz?:string){
    if (hz == undefined) {
      hz = ''
    }
    if (num == null || isNaN(num)) {
      return '<span class="price_draw">' + text + '</span>'
    }
    else{
      num = num / 1
    }

    if (num > 0) {
      return '<span class="price_up">' + text + hz + '</span>'
    }
    else if (num < 0) {
      return '<span class="price_down">' + text + hz + '</span>'
    } 
    else if (num == 0) {
      return '<span class="price_draw">' + text + hz + '</span>'
    }
    else{
      return '<span class="price_draw">' + text + '</span>'
    }
  },  
  /**
   * 
   * @param num 传入的数字
   * @param zoom
   * @param hz 后缀
   */
  numColor: function(num: any,zoom: number, hz: string,fixed: number=2){
    if(num == null || isNaN(num) ){
      return num
    }else{
      num = num*zoom
    }
    if (num > 0) {
      return '<span class="red">' + num.toFixed(fixed) + hz + '</span>'
    }else if(num < 0){
      return '<span class="green">' + num.toFixed(fixed) + hz + '</span>'
    }else{
      return '<span>' + num.toFixed(fixed) + hz + '</span>'
    }

  },
  /**
   * 对放大的行情数字缩小处理
   * @param number1 
   * @param zoom 
   */
  quoteNumZoomOut: function(number1:any, zoom:any){
    if (number1 == null || number1 === '' || isNaN(number1) || zoom == null || zoom === '' || isNaN(zoom)) {
      return '-'
    }
    return (number1 / (Math.pow(10, zoom))).toFixed(zoom)
  },
  formatNum: function(num:any, fixed:number = 2){
    if(num == 0) {
      return num
    }
    if(num == undefined || num == '' || isNaN(num)){
      return '';
    }

    var hz = '';
    if(num >= 100000000||num <= -100000000){
      num = num / 100000000;
      hz = '亿';
    }
    else if(num >= 10000||num <= -10000){
      num = num / 10000;
      hz = '万';
    }
    else{
      return num;
    }

    var num2 = num.toFixed(fixed);

    // if(parseInt(num) >= 1000){ //整数部分超过4位
    //   num2 = num.toFixed(1);
    // }

    return num2.toString() + hz;
  },
  numToFixed: function (input:any, fixnum: number) {
    try {
      return input.toFixed(fixnum)
    } catch (error) {
      return input
    }
  },
  /**
   * 全角转半角
   * @param str 
   */
  ToCDB(str:string) { 
    var tmp = ""; 
    for(var i=0;i<str.length;i++){ 
        if (str.charCodeAt(i) == 12288){
            tmp += String.fromCharCode(str.charCodeAt(i)-12256);
            continue;
        }
        if(str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375){ 
            tmp += String.fromCharCode(str.charCodeAt(i)-65248); 
        } 
        else{ 
            tmp += String.fromCharCode(str.charCodeAt(i)); 
        } 
    } 
    return tmp 
  },
  /**
   * 全角转半角，并删除空白字符
   * @param str 
   */
  ToCDBRemoveBlank(str:string){
    str = this.ToCDB(str)
    return str.replace(/\s/g, '')
  },
  format(str:string, ...values:string[]):string{
    if (values.length > 0) {

      for (var i = 0; i < values.length; i++) {
          if (values[i] != undefined) {
              var reg= new RegExp("(\\{" + i + "\\})", "g");
              str = str.replace(reg, values[i]);
          }
      }
    }
    return str;
  },
  /**
   * 高亮关键字
   * @param txt 需替换的文本 
   * @param keyword 关键字
   * @param keyword2 关键字2，可选
   * @returns 
   */
  highlight(txt:string, keyword:string, keyword2?:string){
    if(txt){
      if(keyword2){
        return txt.replaceAll(keyword, `<em>${keyword}</em>`).replaceAll(keyword2, `<em>${keyword2}</em>`)
      }
      return txt.replaceAll(keyword, `<em>${keyword}</em>`)
    }
    return txt
   
  },

  /**
   * 高亮关键字并截取文本
   * @param txt 需替换的文本 
   * @param keyword 关键字
   * @param keyword2 关键字2，可选
   * @returns 
   */
  highlightTxtLeft(txt:string, n:number, keyword:string){
  
    if( txt == null || txt == "" ){
      return "";
    }

    var txtre = txt

    var thislength = 0;
    var postfix = '...'
    for (var i = 0; i < txt.length; i++) {
      if (txt.charCodeAt(i) > 255) {
        thislength += 2;
      }
      else {
        thislength++;
      }
      if (thislength > n + postfix.length) {
        txtre = txt.substring(0, i)
        // return '<span title="' + txt + '">' +  + postfix + "</span>";
        break;
      }
    }
    // return txt;
    if(txtre == txt){
      return '<span title="' + txt + '">' + txtre.replaceAll(keyword, `<em>${keyword}</em>`) + "</span>";
    }
    return '<span title="' + txt + '">' + txtre.replaceAll(keyword, `<em>${keyword}</em>`) + postfix + "</span>";
    // return 
  },  

  /**
   * 亿万化数字,会根据位数判断
   * @param num 数字或者类数字 
   * @param fixed 保留小数位数,如果不填，就是按整数和小数部分加起来4位处理
   */
  formatNumWithLeng: function(num:any, fixed?:number){
    
    if(isNaN(num)){
      return num.toString()
    }
    let thisnum = Number(num)

    if(thisnum == 0){
      return '0'
    }

    var hz = '';
    if(thisnum >= 1000000000000||thisnum <= -1000000000000){
      thisnum = thisnum / 1000000000000;
      hz = '万亿';
    }    
    else if(thisnum >= 100000000||thisnum <= -100000000){
      thisnum = thisnum / 100000000;
      hz = '亿';
    }
    else if(thisnum >= 10000||thisnum <= -10000){
      thisnum = thisnum / 10000;
      hz = '万';
    }

    if(fixed != undefined){
      var str = thisnum.toFixed(fixed)
      if(/^0+$/.test(str.substring(str.indexOf('.') + 1))){
        return str.substring(0, str.indexOf('.')) + hz
      }
      return str + hz
    }
    if(fixed == undefined){
      var str = thisnum.toString()
      if(str.indexOf('.') >= 0){
        let maxlength = 4
        if(str.indexOf('-') == 0) maxlength = 5
        let fixnum = maxlength - str.indexOf('.')
        if(fixnum >= 0){
          return thisnum.toFixed(fixnum) + hz
        }
        else{
          return thisnum.toFixed(0) + hz
        }
      }
    }
    return thisnum.toString() + hz
  },
  thousandFormatNumber (num:number) {  
    var reg=/\d{1,3}(?=(\d{3})+$)/g;   
    return (num + '').replace(reg, '$&,');  
  }
}