/**
 * 返回顶部
 */

import React,{useState, useEffect} from "react"
import './index.less'

function BackTop(){

  const backtop = (e: React.MouseEvent) =>{
    e.preventDefault()
    $('html, body').animate({
      scrollTop: 0
    }, 200);
  }

  return (
    <>
      <div className="backtop">
        <a className="feedback" href="https://corp.eastmoney.com/liuyan.html" target="_blank" title="意见反馈" />
        <a className="gotop" href="#" onClick={backtop} target="_self" title="回到顶部" />
      </div>
    </>
  )
}

export default BackTop