/**
 * 用户信息
 */

import cookie from '../cookie'

let thisuser:any = undefined

/**
 * 用户
 */
var user = {
    /**
     * 获取用户信息
     */
    get: function():null|{id:string, nick:string}{
        if(thisuser != undefined) return thisuser
        if (cookie.get('ut') && cookie.get('ct') && cookie.get('uidal')) {
            thisuser = {
              id: cookie.get('uidal')!.substring(0,16),
              nick: cookie.get('uidal')!.substring(16)
            };
        }
        else{
            thisuser = null
        }
        return thisuser; 
    },
    /**
     * 退出登录
     * @param  {function} 退出之后回调
     */
    logOut: function (callback?:Function) {
        var date = new Date();
        document.cookie = "pi=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();
        document.cookie = "ct=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();
        document.cookie = "ut=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();
        document.cookie = "uidal=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();
        document.cookie = "vtpst=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();  
        document.cookie = "usercenterck=;path=/;domain=eastmoney.com;expires=" + date.toUTCString();                
        if (callback) {
            callback();
        }
    },
    isLogin: function (){
        if( this.get() ){
            return true;
        }
        else{
            return false;
        }
    },
    /**
     * 跳转登录页面
     */
    goLoginPage: function(){
        self.location.href = `https://passport2.eastmoney.com/pub/login?backurl=${encodeURIComponent(self.location.href)}`
    }
};

export default user



