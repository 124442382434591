/**
 * 新首页
 */
import "./common";

import React from "react";
import ReactDom from "react-dom";
import Welcome from "../components/welcome";

ReactDom.render(
  React.createElement(Welcome),
  document.getElementById('app')
)

