//前端用接口地址
import url from "../modules/url";

interface configitem{
  /** 搜索接口 */
  search: string,
  /* 搜索老接口更换域名 */
  search_new: string,
  /* 搜索老接口更换域名 */
  search_new_new: string,  
  /** 搜索老接口 */
  old_search:string,
  /** 组合接口 */
  group: string,
  /** 股吧接口 */
  guba: string,
  /** 老股吧接口 */
  old_guba: string,  
  /** 行情接口  */
  quote: string,
  /** 行情历史相关接口 */
  quote_history:string,
  /** 数据中心接口 liguangjie */
  datacenter: string,
  /** 数据中心接口 */
  datacenter_new:string,
  /** 数据中心接口 */
  datacenter_3:string,
  /** 数据中心 */
  datainterface: string,
  /** 数据中心4期 */
  datacenter4:string,
  /** data.eastmoney.com */
  data: string,
  /** 推荐资讯接口 */
  recommend: string,
  /** 数据最新接口 */
  data_web: string,
  data_web_test: string;
}

const config:{
  test: configitem,
  test2: configitem,
  production: configitem,
  getApiUrl:(name: keyof configitem)=>string
} = {
  test:  <configitem>{
    search: "//search-api-web-zptest.emapd.com/",
    search_new: "//searchadapter-zptest.eastmoney.com/",
    search_new_new: "//search-codetable-zptest.eastmoney.com/",
    old_search: '//api.so.zptest.emapd.com/',
    group: "//simopertest.eastmoney.com/",
    guba: "//gbapi.eastmoney.com/",
    old_guba: "//gwapi.eastmoney.com/",
    quote_history: "//push2his.eastmoney.com/",
    quote: "//push2test.eastmoney.com/",
    // quote: "//push2.eastmoney.com/",
    datacenter: "//dcfm.eastmoney.com/",
    datacenter_new: "//datacenter.eastmoney.com/",
    datacenter_3: "//datainterface3.eastmoney.com/",
    //datacenter4: "//testdatacenter.eastmoney.com/web/api/",
    datacenter4: "//datacenter-web.eastmoney.com/api/",
    datainterface: "//datainterface.eastmoney.com/",
    data: '//data.eastmoney.com/',
    recommend: "//recommend.eastmoney.com/",
    //data_web:"//testdatacenter.eastmoney.com/",
    data_web: "//datacenter-web.eastmoney.com/",
  },
  test2: <configitem>{
    search: "//search-api-web.eastmoney.com/",
    search_new: "//searchadapter-zptest.eastmoney.com/",
    search_new_new: "//search-codetable-zptest.eastmoney.com/",
    old_search: '//api.so.eastmoney.com/',
    group: "//spoapi.eastmoney.com/",
    guba: "//gbapi.eastmoney.com/",
    old_guba: "//gwapi.eastmoney.com/",
    quote_history: "//push2his.eastmoney.com/",
    quote: "//push2.eastmoney.com/",
    datacenter: "//dcfm.eastmoney.com/",
    datacenter_new: "//datacenter.eastmoney.com/",
    datacenter_3: "//datainterface3.eastmoney.com/",
    datacenter4: "//datacenter-web.eastmoney.com/api/",
    datainterface: "//datainterface.eastmoney.com/",
    data: '//data.eastmoney.com/',
    recommend: "//recommend.eastmoney.com/",
    data_web: "//testdatacenter.eastmoney.com/",
  },  
  production: <configitem>{
    search: "//search-api-web.eastmoney.com/",
    search_new: "//searchadapter.eastmoney.com/",
    search_new_new: "//search-codetable.eastmoney.com/",
    old_search: '//searchapi.eastmoney.com/',
    group: "//spoapi.eastmoney.com/",
    guba: "//gbapi.eastmoney.com/",
    old_guba: "//gwapi.eastmoney.com/",
    quote_history: "//push2his.eastmoney.com/",
    quote: "//push2.eastmoney.com/",
    datacenter: "//dcfm.eastmoney.com/",
    datacenter_new: "//datacenter.eastmoney.com/",
    datacenter_3: "//datainterface3.eastmoney.com/",
    datacenter4: "//datacenter-web.eastmoney.com/api/",
    datainterface: "//datainterface.eastmoney.com/",
    data: '//data.eastmoney.com/',
    recommend: "//recommend.eastmoney.com/",
    data_web: "//datacenter-web.eastmoney.com/",
  },
  getApiUrl(name) {
    const env = url.getQuery('env')
    if (env == "test") {
      return this.test[name]
    }
    if (env == "test2") {
      return this.test2[name]
    }    
    else {
      return this.production[name]
    }
  }
}



export default config