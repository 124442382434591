/**
 * 今日热搜
 */

import React,{useState, useEffect} from "react"
import './index.less'
import data, {hotsearchitem} from './data'

/** 今日热搜 */
function HotSearch(){

  const [showdata, setShowData] = useState([] as Array<hotsearchitem>)

  useEffect(()=>{
    data().then(list=>{
      // console.info(list)
      setShowData(list)
    })
  }, [])

  if(showdata.length == 0){
    return (
      <></>
    )
  }

  return (
    <>
      <div className="hot_search">
        <div className="hot_search_t">
            <span>今日热搜</span> <img src="/content/img/hot.png" />
        </div>
        <ul className="hot_search_l1" tracker-eventcode="dfcfwss.ssh.jrrs.nrdj">
          {showdata.slice(0,10).map((v,index)=>
            <li key={v.KeyPhrase}>
              <span className={`seq seq${index + 1}`}>{index + 1}</span>
              <a target="_blank" title={v.KeyPhrase} href={v.JumpAddress}>{v.KeyPhrase}</a>
              {v.HotKeywordStatus == '3' &&
                <span className="hot_new">新</span>
              }
            </li>            
          )}
        </ul>
        <ul tracker-eventcode="dfcfwss.ssh.jrrs.nrdj">
          {showdata.slice(10,20).map((v,index)=>
            <li key={v.KeyPhrase}>
              <span className={`seq seq${index + 10}`}>{index + 11}</span>
              <a target="_blank" title={v.KeyPhrase} href={v.JumpAddress}>{v.KeyPhrase}</a>
              {v.HotKeywordStatus == '3' &&
                <span className="hot_new">新</span>
              }
            </li>            
          )}
        </ul>        
        <ul>
          
        </ul>
      </div>

    </>
  )
}

export default HotSearch