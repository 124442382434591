/**
 * 推荐资讯接口
 */

import config from "../../../config/web";
import cookie from "../../cookie";
import user from "../../user";

export interface type_recommend_news{
  Id: string,
  ImageUrl: string,
  LightTags: string,
  Tags: string,
  Title: string,
  Type: string,
  Url: string
}

let thisuser = user.get()

export default {
  /**
   * 获取欢迎页面推荐资讯列表
   */
  async getWelcomeList():Promise<Array<type_recommend_news>>{
    return $.ajax({
      url: `${config.getApiUrl('recommend')}soapi/api/articlesearch/search?cb=?`,
      type: 'GET',
      dataType: 'json',
      data: {
        count: 12,
        Userid: thisuser != null ? thisuser.id : '',
        id: cookie.get('qgqp_b_id') || ''
      }
    })
  }
}